import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Card",
  "type": "Content",
  "icon": "creditCardLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Card`}</strong>{` component should be used to create a card or wrap content around that should
have visual difference between the application background. A good example of using a
`}<strong parentName="p">{`Card`}</strong>{` is also for the main content.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Card`}</strong>{` is still under construction. There will be additional component configuration
once there is a better use case of it.`}</p>
    </blockquote>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true background=layout padding=true",
        "live": "true",
        "background": "layout",
        "padding": "true"
      }}>{`<Card>Content of a card</Card>
`}</code></pre>
    <h2>{`Plain card`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true background=layout padding=true",
        "live": "true",
        "background": "layout",
        "padding": "true"
      }}>{`<Card plain>Content of a plain card</Card>
`}</code></pre>
    <h1>{`Props`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Card`}</strong>{` component passes all its props to the underlying `}<em parentName="p">{`div`}</em>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`plain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Renders the card as a plain card`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      